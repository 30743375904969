<template lang="pug">
	.information
		section.information__details
			.landing__container.information__container
				h2.information__title {{ $t('bankDetails.title') }}
				.information__table
					template(v-if="!isMobile")
						.information__item(v-for='detail in bankDetails' :key="detail.id")
							span.information__label(v-html="detail.title")
							span.information__field(v-html="detail.txt")
					template(v-if="isMobile")
						.information__item(v-if="index % 2 === 0" v-for='(detail, index) in bankDetails' :key="detail.id")
							span.information__label(v-html="detail.title")
							span.information__field(v-html="detail.txt")
						.information__item(v-if="index % 2 === 1" v-for='(detail, index) in bankDetails' :key="detail.id")
							span.information__label(v-html="detail.title")
							span.information__field(v-html="detail.txt")


		section.information__contacts
			.landing__container.information__container
				h2.information__title {{ $t('contacts.title') }}
				.information__table
					.information__item
						span.information__label {{ $t('user.email') }}
						span.information__field mail@paywall.pw
					.information__item(v-if="isMobile")
						span.information__label {{ $t('contacts.items[1].title') }}
						span.information__field {{ $t('contacts.items[1].txt') }}
					.information__item
						span.information__label {{ $t('contacts.items[0].title') }}
						span.information__field {{ $t('contacts.items[0].txt') }}
					.information__item(v-if="!isMobile")
						span.information__label {{ $t('contacts.items[1].title') }}
						span.information__field {{ $t('contacts.items[1].txt') }}
					.information__item(v-if="!isMobile")

</template>

<script>

export default {
	name: 'Information',
	computed: {
		bankDetails() {
			return this.$t('bankDetails.items');
		}
	},
}
</script>

<style lang="scss">

	.information {
		&__container {
			max-width: 1152px !important;
		}

		&__details {
			padding: 64px 0 120px;

			@media (max-width: 991.98px) {
				padding-top: 60px;
				padding-bottom: 96px;
			}
	
			@media (max-width: 767.98px) {
				padding-top: 56px;
				padding-bottom: 72px;
			}
	
			@media (max-width: 575.98px) {
				padding-top: 52px;
				padding-bottom: 48px;
			}
		}

		&__title {
			margin-bottom: 64px !important;

			@media (max-width: 991.98px) {
				margin-bottom: 52px !important;
			}
	
			@media (max-width: 767.98px) {
				margin-bottom: 39px !important;
			}
	
			@media (max-width: 575.98px) {
				margin-bottom: 27px !important;
			}
		}

		&__table {
			border: 1px solid var(--lc-light-grey);
			border-radius: 20px;
			padding: 0 23px;
			max-width: 920px;
			display: flex;
			flex-wrap: wrap;

			@media (max-width: 767.98px) {
				padding-top: 7px;
			}
		}

		&__item {
			width: 50%;
			padding: 24px 0 23px;
			border-bottom: 1px solid var(--grayscale-line-border);

			&:last-of-type, &:nth-last-child(2) {
				border: 0;
			}

			@media (max-width: 767.98px) {
				width: 100%;
				padding: 16px 0 23.5px;
				border-bottom: 1px solid var(--grayscale-line-border) !important;

				&:last-of-type {
					border: 0 !important;
				}
			}
		}

		&__label {
			display: block;
			font-weight: 400;
			color: var(--lc-grey-2);
			margin-bottom: 16px;

			@media (max-width: 991.98px) {
				font-size: 15px;
			}
	
			@media (max-width: 767.98px) {
				font-size: 14px;
			}
	
			@media (max-width: 575.98px) {
				font-size: 13px;
			}
		}

		&__field {
			font-size: 18px;
			line-height: 1;
	
			@media (max-width: 767.98px) {
				font-size: 17px;
			}
	
			@media (max-width: 575.98px) {
				font-size: 16px;
			}
		}

		&__contacts {
			background-color: var(--lc-pale-silver);
			padding: 85px 0 120px;

			@media (max-width: 991.98px) {
				padding-top: 72px;
				padding-bottom: 96px;
			}
	
			@media (max-width: 767.98px) {
				padding-top: 60px;
				padding-bottom: 73px;
			}
	
			@media (max-width: 575.98px) {
				padding-top: 47px;
				padding-bottom: 49px;
			}
		}
	}

</style>