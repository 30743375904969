<template lang="pug">
	.landing.landing-about
		div
			div
				Header(@click="goToLogin")
				.landing__about
					.pt-0.landing__container
						Banner
				Information
				Features
				FooterRuDomain

			ModalCookie
</template>

<script>
import { mapActions } from 'vuex';

import Header from '@/components/landingPage/Header';
import FooterRuDomain from '@/components/landingPage/FooterRuDomain';
import ModalCookie from '@/components/Modals/Cookie';

import Banner from '@/components/landingPage/About/Banner';
import Information from '@/components/landingPage/About/Information';
import Features from '@/components/landingPage/About/Features';

export default {
	name: 'landingAbout',
	metaInfo() {
		return {
			titleTemplate: this.islocaleRuSystemAndIp ? 'Paywall - монетизация контента, сообществ и нейросетей в Телеграме.' : 'Paywall - Content monetization service',
		};
	},
	components: {
		Header,
		FooterRuDomain,
		ModalCookie,

		Banner,
		Information,
		Features
	},
	created() {
		this.viewGeoIpRussia();
	},
	mounted()	{
		window.addEventListener('beforeunload', this.handleBeforeUnload);

		if (this.$route.hash) {
			setTimeout(() => this.scrollTo(this.$route.hash), 100)
		}

		this.rememberPageView();
	},
	beforeMount () {
		this.redirectToLoginIfMerchantPath();
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
	methods: {
		...mapActions({
			getGeoIpRussia: 'auth/geoIpRussia'
		}),
		redirectToLoginIfMerchantPath () {
			if ( window.location.href.includes('merchant') ) {
				if( this.me && this.me.id ) return this.$router.replace({name: 'projects'})

				return this.goToLogin();
			}
		},
		goToLogin(e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			window.location.href = this.loginUrl;
		},
		handleBeforeUnload() {
			window.scrollTo({ top: 0, behavior: "instant"});
		}
	},
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_landing.style.scss';

.landing-about {
	h1, h2 {
		@media (max-width: 991.98px) {
			font-size: 40px !important;
		}

		@media (max-width: 767.98px) {
			font-size: 32px !important;
		}

		@media (max-width: 575.98px) {
			font-size: 24px !important;
		}
	}
}
</style>
